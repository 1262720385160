
<template>
  <swiper class="swiper d-flex" :options="swiperOption">
    <swiper-slide v-for="slide in slides" :key="slide.name"><img :src="slide[1].image"
                                               class="logo img-responsive center-block"
                                               :alt="slide[1].name"></swiper-slide>
  </swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    name: 'swiper-example-multiple-slides-per-biew',
    title: 'Multiple slides per view',
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      slides: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        swiperOption: {
          slidesPerView: 6,
          spaceBetween: 30,
          // centeredSlides: true,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false
          },
          loop: true,
          breakpoints: {
            1024: {
              slidesPerView: 6,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 30
            },
            320: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          }
          // pagination: {
          //   el: '.swiper-pagination',
          //   clickable: true
          // }
        }
      }
    },
    mounted() {

      this.$nextTick(function () {
        console.log(this.slides)
        // Code that will run only after the
        // entire view has been rendered
      })
    }
  }
</script>

<style lang="scss" scoped>
  /*@import './base.scss';*/

  .swiper-slide {
    display: flex;
    align-self: center;
  }

  .logo {
    max-width:100px;
    max-height:100px;
    @media(min-width:992px){
      max-width:120px;
      max-height:120px;
    }
  }
</style>