import "core-js/stable";
import "regenerator-runtime/runtime";

/* eslint no-console:0 */
// var Turbolinks = require("turbolinks")
// Turbolinks.start()

// import "../css/application.css"
import Vue from 'vue/dist/vue.esm'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

// import TurbolinksAdapter from 'vue-turbolinks'
// Vue.use(TurbolinksAdapter)

import VueFormulate from '@braid/vue-formulate'
Vue.use(VueFormulate)
console.log("application loaded")

import App from '../app.vue'
import Slider from '../components/home/Slider.vue'
import Appointment from '../profile/Appointment';
Vue.component('app', App)
Vue.component('slider', Slider)
Vue.component('appointment', Appointment)

// new Vue({
//   el: '#wtcApp',
//   render: h => h(App)
// })

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '[data-behavior="vue"]',
  })
})