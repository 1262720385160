<template>
  <div>
    <h3>Book an appointment</h3>
    <div v-if="!submitted" class="row">
      <div class="col-md-4">
        <flat-pickr
          :config="config"
          class="form-control"
          @on-change="dateChanged"
          name="appt_dates">
        </flat-pickr>
      </div>
      <div class="col-md-4">
        <h3 v-if="selected_date">Choose a time for {{ selected_date }}</h3>
        <p v-if="selected_date">Viewing times in {{ user_tz }}</p>
        <div style="max-height: 250px; display: flex; flex-direction: column; flex-wrap: wrap;">
          <button v-for="av in available_times" :disabled="appointments.includes(av)" @click="timeSelected(av)" class="btn btn-primary"
                  style="display: block; margin-bottom: 5px; min-width: 95px; margin-right: 15px;">{{ av }}
          </button>
        </div>
      </div>
      <div class="col-md-4" v-if="selected_time">
        <h3>Complete the form:</h3>

        <p>You've selected {{ selected_time }} on {{ selected_date }}</p>


        <FormulateForm
          class="login-form"
          v-model="formValues"
          @submit="submitHandler"
        >
          <FormulateInput type="text" name="fname" validation-name="First Name" validation="required" placeholder="First Name"/>
          <FormulateInput type="text" name="lname" validation-name="Last Name" validation="required" placeholder="Last Name"/>
          <FormulateInput type="email" name="email" validation-name="Email" validation="required|email" placeholder="Email"/>
          <FormulateInput
            type="submit"
            label="Submit"
          />
        </FormulateForm>
      </div>
    </div>

    <div v-else style="min-height: 300px;">
      <h4>Thank you! Check your email for a confirmation email and more details.</h4>
    </div>

  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import axios from "axios";
import format from "date-fns/format";
import addMinutes from "date-fns/add_minutes";
import compareAsc from 'date-fns/compare_asc';
import jstz from 'jstz';

export default {
  components: {
    flatPickr
  },
  props: {
    availabilities: {
      type: Array,
      default: []
    },
    school_tz: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formValues: {},
      config: {
        inline: true,
        enable: this.availabilities,
        minDate: "today",
        disable: [
          date => {
            if (this.availabilities.length === 0) {
              return true
            }
          }
        ]
      },
      available_ranges: [],
      available_times: [],
      appointments: [],
      selected_date: '',
      selected_time: '',
      savingAppointment: false,
      submitted: false,
      user_tz: ''
    }
  },
  methods: {
    submitHandler(e) {
      let params = {}
      params.appointment = e;
      params.appointment.date = this.selected_date
      params.appointment.time = this.selected_time
      params.appointment.user_tz = this.user_tz
      params.appointment.school_tz = this.school_tz
      let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      this.savingAppointment = true;
      // let path = `http://${window.location.hostname}:3000/${window.location.pathname}`
      let path = `https://${window.location.hostname}/${window.location.pathname}`

      axios
        .post(`${path}/appointments`, params, {
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-TOKEN': csrf
          },
        })
        .then(response => {
          this.submitted = true;
          this.savingRegistration = false;
        })
        .catch(error => {
          this.savingRegistration = false;
          console.log(error);
        });
    },
    timeSelected(e) {
      this.selected_time = e;
    },
    dateChanged(e) {
      this.available_times = []
      this.selected_time = ''
      let newdate = format(e, "YYYY/MM/DD")
      let formatted_date = format(e, 'MMM DD, YYYY')
      this.selected_date = formatted_date;
      // let path = `http://${window.location.hostname}:3000/${window.location.pathname}`
      let path = `https://${window.location.hostname}/${window.location.pathname}`

      axios
        .get(`${path}/appointments.json?date=${newdate}`, {})
        .then(response => {
          this.appointments = response.data
        })
        .catch(error => {
          console.log(error);
        });

      axios
        .get(`${path}/availabilities.json?date=${newdate}`, {})
        .then(response => {
          this.available_ranges = response.data
          response.data.forEach((range) => {
            let parsedStartDate = new Date(range.start);
            let parsedEndDate = new Date(range.end);
            while (parsedStartDate < parsedEndDate) {
              this.available_times.push(format(parsedStartDate, 'h:mm a'))
              parsedStartDate = addMinutes(parsedStartDate, 30)
            }
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    var timezone = jstz.determine();
    console.log(timezone.name());
    this.user_tz = timezone.name();
  }
}
</script>

<style>
.formulate-input-element input {
  width: 100%;
}
</style>

<style scoped>
.flatpickr-input {
  display: none;
}
</style>